import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import App from "./App";
import "./index.css";
import NotFound from "./pages/404";
import Logger from "./pages/Logger";
import LoggerVisual from "./pages/LoggerVisual";
import Logger_v0 from "./pages/Logger_v0";
import reportWebVitals from "./reportWebVitals";

const routing = (
  <Router>
    <Switch>
      <Route exact path="/" component={App} />
      <Route path="/e" component={Logger_v0} />
      <Route path="/v1" component={Logger} />
      <Route path="/404" component={NotFound} />
      <Route path="/lv" component={LoggerVisual} />
    </Switch>
  </Router>
);
ReactDOM.render(routing, document.getElementById("root"));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
