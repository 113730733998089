import * as c3 from "c3";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoggerVisualBody from "../components/LoggerVisualBody";
import {
  getNewsletterData, getQuestionData, getQuestionVotes, pushVote
} from "../db";

function LoggerVisual() {
  let location = useLocation();

  const [loaded, setLoaded] = useState(false);
  const [title, setTitle] = useState("");
  const [newsletterTitle, setNewsletterTitle] = useState("");

  useEffect(() => {
    async function executeRedirect() {
      if (location.search !== "") {
        const userData = parseURL(location.search);
        if (localStorage.getItem("voted") === "true") {
          toast("You've already voted!", {
            hideProgressBar: true,
          });
          updateBarGraph(userData["qid"]);
        } else {
          let redirect = await pushVote(
            userData["nid"],
            userData["qid"],
            userData["vote"]
          );
          if (redirect != null) {
            updateBarGraph(userData["qid"]);
            getNewsletterData(userData["nid"]).then((snap) => {
              let data = snap.val();
              if (data) {
                setNewsletterTitle(data["newsletter_name"]);
              }
            });
            localStorage.setItem("voted", "true");
          } else {
            window.location = "/404";
          }
        }
      }
    }
    executeRedirect();

    // updateBarGraph("-Me3IhaEgUL3RaGQDZ72");
    async function updateBarGraph(question_id) {

      getQuestionVotes(question_id).then((snap) => {
        const vote_info = snap.val();

        getQuestionData(question_id).then((snap1) => {
          const options = snap1.val()["options"];
          const title = snap1.val()["question_text"];
          if (title !== undefined && title !== null) {
            setTitle(title);
          }

          // Make objects like this
          // {day: number of good votes on that day}
          // {day: number of bad votes on that day}

          var vote_types = options;
          var distinct_votes = [];

          const distinct = (value, index, self) => {
            return self.indexOf(value) === index;
          };

          if (vote_info !== undefined && vote_info) {
            Object.entries(vote_info).forEach((entry) => {
              const [_, info] = entry;
              const question_vote = info["vote"];
              // Get a list of distinct types of votes there are
              distinct_votes.push(question_vote);
            });
            distinct_votes = distinct_votes.filter(distinct).sort();
          }

          const column_vals = [[]];
          for (let i = 0; i < options.length; i++) {
            column_vals[0].push(0);
          }

          distinct_votes.forEach((vote) => {
            var curr_vote_count = 0;
            Object.entries(vote_info).forEach((entry) => {
              const [_, info] = entry;
              const question_vote = info["vote"];
              if (question_vote === vote) {
                curr_vote_count += 1;
              }
            });
            column_vals[0][vote] = curr_vote_count;
          });
          column_vals[0].splice(0, 0, "votes");
          var empty_column_vals = JSON.parse(JSON.stringify(column_vals));
          for (let i = 1; i < empty_column_vals[0].length; i++) {
            empty_column_vals[0][i] = 0;
          }
          empty_column_vals[0][1] = 0;

          var barChart = c3.generate({
            data: {
              columns: empty_column_vals,
              type: "bar",
              colors: { votes: "#FC906A" },
            },
            bar: {
              width: {
                ratio: 0.75, // this makes bar width 50% of length between ticks
              },
              // or
              //width: 100 // this makes bar width 100px
            },
            transition: {
              duration: 1500,
            },
            legend: {
              show: false,
            },
            tooltip: {
              show: false,
            },
            axis: {
              x: {
                type: "categories",
                categories: vote_types,
                show: true,
                height: 60,
                padding: {
                  left: 0.2,
                  right: 0.2,
                },
              },
              y: {
                show: true,
                padding: {
                  top: 120,
                },
                tick: {
                  format: function (d) {
                    if (Math.floor(d) !== d) {
                      return;
                    }
                    return d;
                  },
                },
              },
            },
            bindto: "#barChart",
          });

          barChart.load({
            columns: column_vals,
          });
          setLoaded(true);
        });
      });
    }
  }, [location.search]);

  const parseURL = (url) => {
    // for each split, split again and put into object
    url = url.substring(1); // get rid of question mark
    let split_url = url.split("&");
    let obj = {};
    for (let i = 0; i < split_url.length; i++) {
      const key_val_array = split_url[i].split("=");
      obj[key_val_array[0]] = key_val_array[1];
    }
    return obj;
  };

  return (
    <div className="App">
      <img
        alt="Feedbee"
        style={{ zIndex: 3 }}
        className="logo"
        src={process.env.PUBLIC_URL + "/logo192 copy.png"}
      ></img>
      <LoggerVisualBody
        loaded={loaded}
        title={title}
        newsletterName={newsletterTitle}
      />
      <ToastContainer autoClose={3000} />
    </div>
  );
}

export default LoggerVisual;
