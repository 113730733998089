import { useEffect } from "react";
import "./App.css";
// import { copyRef, makeNewUser, makeMCQuestionType, makeNewNewsletterWriter, printUrls, makeNewNewsletter } from "./db";
function App() {
  useEffect(() => {
    // makeNewUser("Seb", "Kennedy", "seb@energyflux.news", "https://docs.google.com/forms/d/e/1FAIpQLSfKTwZ4j1WZauhEH8CLIC5h2YVUNYaGAj-i1vB4ZbY9us0Pmg/viewform?usp=sf_link")
    // copyRef("users/-Md-upVEoQnzJsYZf6BE", "newsletter-writer/-Md-upVEoQnzJsYZf6BE")
    // makeMCQuestionType(["Bad", "Meh", "Good", "Awesome"], ["#065a70", "#518895", "#8db8bc", "#cbe9e8"])
    // makeNewNewsletterWriter("Feed", "Bee", "tryfeedbee@gmail.com", "-Me7xAwq2hq216UBqLwX")
    // printUrls("-Me7xAwq2hq216UBqLwX")
    // makeNewNewsletter("FeedBee", "-Me7uuBgJv3lsuABsnsT", "https://docs.google.com/forms/d/e/1FAIpQLSfj6LznlbBg-eMQwYxOYVINdjQDXjQgcHZCDc67PgvcMnauIw/viewform?usp=sf_link")
  }, []);

  return (
    <div className="App" style={{ margin: "20px" }}>
      Looks like you ended up in the wrong place! Send us an email at tryfeedbee
      [at] gmail [dot] com if you're interested in the product
    </div>
  );
}

export default App;
