/*

We will also tag constants in the same way as db functions
**Once a variable has been deprecated**, there will be a version number after the constant like so:

CONSTANT_NAME_V1
CONSTANT_NAME_V1_1 (version 1.1)

*/

// VERSION 0
export const USER_REF_V0 = "v0/users";
export const USER_TEST_REF = "users-test";
export const VOTE_REF_V0 = "v0/votes";
export const VOTE_TEST_REF = "votes-test";

// VERSION 1
export const NEWSLETTER_WRITER_REF = "newsletter-writer";
export const NEWSLETTER_REF = "newsletter";
export const QUESTION_TYPE_REF = "question-type";
export const VOTE_REF = "vote";
export const QUESTION_TYPE_TEST_REF = "question-type-test";
export const NEWSLETTER_TEST_REF = "newsletter-test";
