import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { pushVote } from "../db";

function Logger() {
  let location = useLocation();

  useEffect(() => {
    async function executeRedirect() {
      if (location.search !== "") {
        const userData = parseURL(location.search);
        let redirect = await pushVote(
          userData["nid"],
          userData["qid"],
          userData["vote"]
        );
        console.log("redirect");
        console.log(redirect);
        if (redirect != null) {
          window.location = redirect;
        } else {
          window.location = "/404";
        }
      }
    }
    executeRedirect();
  }, [location.search]);

  const parseURL = (url) => {
    // for each split, split again and put into object
    url = url.substring(1); // get rid of question mark
    let split_url = url.split("&");
    let obj = {};
    for (let i = 0; i < split_url.length; i++) {
      const key_val_array = split_url[i].split("=");
      obj[key_val_array[0]] = key_val_array[1];
    }
    console.log(obj);
    return obj;
  };

  return <div className="App"></div>;
}

export default Logger;
