import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getUserData_v0, pushVote_v0 } from '../db';

function Logger() {
  let location = useLocation();

  useEffect(() => {
    if (location.search !== "") {
      const userData = parseURL(location.search);
      pushVote_v0(userData["id"], userData["vote"]);
      getUserData_v0(userData['id']).then(snap => {
        window.location = snap.val()['redirect'];
      })
    }
  }, [location.search]);

  const parseURL = (url) => {
    // for each split, split again and put into object
    url = url.substring(1); // get rid of question mark
    let split_url = url.split("&");
    let obj = {};
    for (let i = 0; i < split_url.length; i++) {
      const key_val_array = split_url[i].split("=");
      obj[key_val_array[0]] = key_val_array[1];
    }
    console.log(obj);
    return obj;
  };

  return <div className="App"></div>;
}

export default Logger;
