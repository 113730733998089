import "../App.css";

function NotFound() {

  return (
    <div className="App" style={{ margin: "20px" }}>
      Looks like you ended up in the wrong place! Perhaps something was configured incorrectly. Send us an email at tryfeedbee
      [at] gmail [dot] com and we can figure it out.
    </div>
  );
}

export default NotFound;
