import {
  NEWSLETTER_REF,
  NEWSLETTER_TEST_REF,
  NEWSLETTER_WRITER_REF,
  QUESTION_TYPE_REF,
  QUESTION_TYPE_TEST_REF, USER_REF_V0, VOTE_REF, VOTE_REF_V0,
  VOTE_TEST_REF
} from "./constants";
import db from "./firebase";

/*

Every **deprecated** function is tagged with a version number
This will ensure backwards compatabitlity in our early stages when things are changing fast.

Style Guide:
Functions: functionName_v1, functionName_v1_1 (version 1.1)
Constants: ALL_CAPS_SNAKE_CASE
Variables: snake_case
*/

////////////////////// VERSION 1 //////////////////////

// VERSION 1
export const getNewsletterWriterData = (id) => {
  // production function (make copy to use for testing)
  return db.ref(`${NEWSLETTER_WRITER_REF}/${id}`).once("value");
};

// VERSION 1
export const getNewsletterData = (id) => {
  // production function (make copy to use for testing)
  return db.ref(`${NEWSLETTER_REF}/${id}`).once("value");
};

// VERSION 1
export const pushTestVote = async (newsletter_id, question_id, user_vote) => {
  // production function (make copy to use for testing)

  let news_snap = await db
    .ref(NEWSLETTER_TEST_REF)
    .child(newsletter_id)
    .once("value")
    .then((newsletter_snap) => {
      return newsletter_snap;
    });
  if (news_snap.exists()) {
    return db
      .ref(QUESTION_TYPE_TEST_REF)
      .child(question_id)
      .once("value")
      .then((question_snap) => {
        if (question_snap.exists()) {
          // if the question type id exists
          db.ref(VOTE_TEST_REF).push({
            // push the vote
            newsletter_id: newsletter_id,
            question_id: question_id,
            vote: user_vote,
            timestamp: Date.now(),
          });
          return news_snap.val().redirect;
        } else {
          console.log("Error: question_id doesn't exist");
        }
      });
  }
  return null;
};

// VERSION 1
export const pushVote = async (newsletter_id, question_id, user_vote) => {
  // production function (make copy to use for testing)

  let news_snap = await db
    .ref(NEWSLETTER_REF)
    .child(newsletter_id)
    .once("value")
    .then((newsletter_snap) => {
      return newsletter_snap;
    });
  if (news_snap.exists()) {
    return db
      .ref(QUESTION_TYPE_REF)
      .child(question_id)
      .once("value")
      .then((question_snap) => {
        if (question_snap.exists()) {
          // if the question type id exists
          db.ref(VOTE_REF).push({
            // push the vote
            newsletter_id: newsletter_id,
            question_type_id: question_id,
            vote: user_vote,
            timestamp: Date.now(),
          });
          return news_snap.val().redirect;
        } else {
          console.log("Error: question_id doesn't exist");
        }
      });
  }
  return null;
};

// VERSION 1
export const makeNewNewsletter = (
  newsletter_name,
  active_question_id,
  redirect
) => {
  let params_list = [newsletter_name, active_question_id, redirect];
  for (let i = 0; i < params_list.length; i++) {
    if (
      params_list[i] === undefined ||
      params_list[i] === "" ||
      params_list[i] === null
    ) {
      console.log("Error in makeNewNewsletter parameters");
      return null;
    }
  }
  var newsletter_ref = db.ref(`${NEWSLETTER_REF}`);

  var new_newsletter = newsletter_ref.push({
    newsletter_name: newsletter_name,
    has_paid: false,
    active_question_id: active_question_id,
    question_type_ids: [active_question_id],
    redirect: redirect,
  });

  newsletter_ref
    .child(new_newsletter.key)
    .update({ newsletter_id: new_newsletter.key });
};

// VERSION 1
export const makeNewNewsletterWriter = (
  first_name,
  last_name,
  email,
  newsletter_id
) => {
  // production function (make copy to use for testing)
  let params_list = [first_name, last_name, email, newsletter_id];
  for (let i = 0; i < params_list.length; i++) {
    if (
      params_list[i] === undefined ||
      params_list[i] === "" ||
      params_list[i] === null
    ) {
      console.log("Error in makeNewNewsletterWriter parameters");
      return null;
    }
  }
  var ref = db.ref(`${NEWSLETTER_WRITER_REF}`);
  var newsletter_ref = db.ref(`${NEWSLETTER_REF}`);

  var new_user = ref.push({
    first_name: first_name,
    last_name: last_name,
    email: email,
    newsletter_id: newsletter_id,
  });

  newsletter_ref
    .child(newsletter_id)
    .once("value")
    .then((snap) => {
      let value = snap.val();
      if ("newsletter_writer_uids" in value) {
        let temp = value["newsletter_writer_uids"];
        temp.push(new_user.key);
        newsletter_ref.child(newsletter_id).update({
          newsletter_writer_uids: temp,
        });
      } else {
        let temp = [new_user.key];
        newsletter_ref.child(newsletter_id).update({
          newsletter_writer_uids: temp,
        });
        console.log("wasn't there");
      }
    });
};

// VERSION 1
export const makeMCQuestionType = (options, color_scheme) => {
  let timestamp = Date.now();
  if (Array.isArray(options) && Array.isArray(color_scheme)) {
    if (options.length !== color_scheme.length) {
      console.log("options length different from color scheme length");
      return null;
    }
    return db.ref(`${QUESTION_TYPE_REF}`).push({
      options: options,
      color_scheme: color_scheme,
      is_short_response: false,
      is_visible: false,
      last_updated_timestamp: timestamp,
      created_timestamp: timestamp,
    });
  }
  return null;
};

// VERSION 1
// get all the votes for a given question when given the question ID
export function getQuestionVotes(question_type_id) {
  return db
    .ref(`${VOTE_REF}/`)
    .orderByChild("question_type_id")
    .equalTo(question_type_id)
    .once("value");
}

// VERSION 1
// get the data for a question type, when given its ID
export function getQuestionData(question_id) {
  return db.ref(`${QUESTION_TYPE_REF}/${question_id}`).once("value");
}

// VERSION 1
// get all the votes for a given question when given the question ID
export function getTestQuestionVotes(question_id) {
  return db
    .ref(`${VOTE_TEST_REF}/`)
    .orderByChild("question_id")
    .equalTo(question_id)
    .once("value");
}

// VERSION 1
// get the data for a question type, when given its ID
export function getTestQuestionData(question_id) {
  return db.ref(`${QUESTION_TYPE_TEST_REF}/${question_id}`).once("value");
}

////////////////////// ADMIN FUNCTIONS //////////////////////

export const copyRef = (ref, new_ref) => {
  // this function is dangerous, be careful with it
  // copies all the data in one node (ref) to a new node (new_ref)
  // DO NOT put the same ref twice
  if (
    ref !== "" &&
    ref !== null &&
    new_ref !== "" &&
    ref !== null &&
    ref !== undefined &&
    new_ref !== undefined
  ) {
    console.log(new_ref);
    db.ref(ref)
      .once("value")
      .then((snap) => {
        db.ref(new_ref).update(snap.val());
      });
  }
  console.log("Not using copyRef correctly");
  return null;
};

export const printUrls = (newsletter_id) => {
  db.ref(NEWSLETTER_REF)
    .child(newsletter_id)
    .once("value")
    .then((n_snap) => {
      let newsletter_snap = n_snap.val();
      db.ref(QUESTION_TYPE_REF)
        .child(newsletter_snap.active_question_id)
        .once("value")
        .then((q_snap) => {
          let question_snap = q_snap.val();
          for (var i = 0; i < question_snap.options.length; ++i) {
            // TODO: change version if necessary
            console.log(
              "https://tryfeedbee.com/v1/?vote=".concat(
                i,
                "&qid=",
                q_snap.key,
                "&nid=",
                newsletter_id
              )
            );
          }
        });
    });
};

////////////////////// VERSION 0 //////////////////////

// VERSION 0
export const getUserData_v0 = (id) => {
  // production function (make copy to use for testing)
  return db.ref(`${USER_REF_V0}/${id}`).once("value");
};

// VERSION 0
export const pushVote_v0 = (newsletter_id, user_vote) => {
  // production function (make copy to use for testing)
  let data = {
    newsletter_id: newsletter_id,
    vote: user_vote,
    timestamp: Date.now(),
  };
  if (newsletter_id.startsWith("-")) {
    return db.ref(`${VOTE_REF_V0}`).push(data);
  }
  return null;
};

// VERSION 0
export const makeNewUser_v0 = (first_name, last_name, email, redirect) => {
  // production function (make copy to use for testing)
  let params_list = [first_name, last_name, email, redirect];
  for (let i = 0; i < params_list.length; i++) {
    if (
      params_list[i] === undefined ||
      params_list[i] === "" ||
      params_list[i] === null
    ) {
      console.log("Error in makeNewUser parameters");
      return null;
    }
  }
  var ref = db.ref(`${USER_REF_V0}`);

  var new_user = ref.push({
    first_name: first_name,
    last_name: last_name,
    email: email,
    redirect: redirect,
  });

  ref.child(new_user.key).update({ newsletter_id: new_user.key });
};
