import React, { useEffect } from "react";
import { Card } from "react-bootstrap";
import "../styles/LoggerVisualBody.css";


export default function LoggerVisualBody({ loaded, title, newsletterName }) {
  useEffect(() => { }, [loaded, title]);

  return (
    <div>
      <Card className="chart-container">
        <Card.Title>{loaded ? title : ""}</Card.Title>
        <div id="barChart"></div>
        <Card.Body>
          <Card.Text>
            {loaded ? (
              <a className="discordLink" href="https://discord.gg/J6VMKWMyjr">
                🧡 &nbsp;These are the votes from the {newsletterName} community!
              </a>
            ) : null}
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
}
